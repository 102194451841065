import React, { useState } from "react";
import TemplateCard from "../../../Common/TemplateCard/TemplateCard";
import * as styles from "./mytemplates.module.scss";
import CreditsModal from "../../../Modals/CreditsModal/CreditsModal";

export default ({ templates }) => {
  const [temps, setTemplates] = useState(templates);

  return (
    <div className={styles.container}>
      <CreditsModal message="You need to upgrade to access your saved Templates" />
      {temps.length ? (
        <div className={styles.templates}>
          {temps.map((template, index) => {
            return (
              <TemplateCard
                setTemplates={setTemplates}
                key={index}
                template={template}
              />
            );
          })}{" "}
        </div>
      ) : (
        <div>
          <h4>You have no saved templates</h4>
          <span>
            Click on the 'Save' button when designing your mockup to save your
            template. It will then appear here.
          </span>
        </div>
      )}
    </div>
  );
};
