import React, { useRef, useState } from "react";
import Tippy from "@tippyjs/react";
import ChevronRightIcon from "../../../assets/svg/3d-maker/triangle-right.svg";
import "tippy.js/dist/tippy.css"; // optional
import "tippy.js/themes/light-border.css";
import { menu, container, mainDiv, disabled } from "./dropdown.module.scss";
import ContentLoader from "react-content-loader"

const OptionLoader = (props) => (
    <ContentLoader 
      speed={2}
      width={160}
      height={24}
      viewBox="0 0 160 24"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="10" y="0" rx="4" ry="4" width="140" height="24" />
    </ContentLoader>
  )

const Menu = ({ options, hide }) => {
  return (
    <ul className={menu}>
      {options.map((option, index) => {
        if (option.options) {
          return (
            <Tippy
              key={index}
              className={container}
              content={option.options.length ? <Menu options={option.options} hide={hide}/> : null}
              theme={"light-border"}
              placement={"right-start"}
              interactive={true}
              trigger={"mouseenter"}
            >
              <li 
              onClick={e => e.stopPropagation()}
              onMouseOver={() => {
                    if(option.onHover) option.onHover()
                }}>
                <div>{option.icon}</div>
                <div>{option.name}</div>
                <div>
                  <ChevronRightIcon />
                </div>
              </li>
            </Tippy>
          );
        } else {
          return (
            option.loading ?
              <OptionLoader key={index}/> :
            <li key={index} className={!option.onClick ? disabled : undefined} onClick={(e) => {
              e.stopPropagation()
              if(option.onClick){
                hide()
                option.onClick()
              }
            }}>
              <div>{option.icon}</div>
              <div>{option.name}</div>
            </li>
          );
        }
      })}
    </ul>
  );
};

const Dropdown = ({parent, options, className, dropdownClassName, style}) => {
const [visible, setVisible] = useState(false);
const show = () => setVisible(true);
const hide = () => setVisible(false);

  return (
    <Tippy
      className={`${dropdownClassName} ${container}`}
      theme={"light-border"}
      content={<Menu options={options} hide={hide}/>}
      placement={"bottom"}
      interactive={true}
      trigger={"click"}
      visible={visible} 
      onClickOutside={hide}
    >
      <div onClick={(e) => {
        e.stopPropagation()
        if(visible) hide()
        else show()
      }} className={`${mainDiv} ${className}`} style={style}>{parent}</div>
    </Tippy>
  );
};

export default Dropdown;
