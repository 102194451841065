import React, { useState } from "react";
import TemplateCard from "../../../../../Common/TemplateCard/TemplateCard";
import * as styles from "./templates.module.scss";
import CreditsModal from "../../../../../Modals/CreditsModal/CreditsModal";

export default ({ templates, groupName }) => {
  const [temps, setTemplates] = useState(templates)

  return (
    <div className={styles.container}>
    <CreditsModal message="You need to upgrade to access your saved Templates" />
      {temps.length ? (
        temps.map((template, index) => {
          return <TemplateCard groupName={groupName} setTemplates={setTemplates} template={template} key={index} />;
        })
      ) : (
        <span>The group has no saved templates.</span>
      )}
    </div>
  );
};
