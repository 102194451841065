import React from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import InviteSvg from '../../../../../../assets/svg/invitation.svg';
import API from '../../../../../../firebase/api';
import Form from '../../../../../Common/Form/Form';
import Modal from '../../../../../Modals/Modal/Modal';
import * as styles from './addmembersmodal.module.scss';

export default ({handleClose, groupId}) => {
    const [emails, setEmails] = React.useState([])

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Invalid e-mail')
          .required('E-mail is required'),
      });

    // function removeEmail(email){
    //     let newEmails = emails.filter(x => x !== email)
    //     setEmails(newEmails)
    // }

    // function addEmail(e){
    //     e.preventDefault()
    //     let email = document.getElementById('emailField').value
    //     if(!emails.includes(email)){
    //         let newEmails = [...emails, email]
    //         setEmails(newEmails)
    //     }
    //     document.getElementById('emailField').value = ''
    // }

    async function onSubmit(values){
        let response = await API.inviteUsersToGroup([values.email], groupId)
        if(response){
            toast.success('✔ Sent')
            handleClose()
        }
        else{
            toast.error('Something went wrong...')
        }
        return
    }

    // const Emails = () => emails.map(email => {
    //     return(
    //         <div>
    //             <span>{email}</span>
    //             <CloseIcon onClick={() => removeEmail(email)}/>
    //         </div>
    //     )
    // })
    return(
        <Modal open={true} handleClose={handleClose}>
            <div className={styles.container}>
                <InviteSvg />
                <h4>Add a new member</h4>
                <p>We will send an email with an invitation to join the group and gain access to the templates.</p>
                <Form onSubmit={onSubmit} submitButtonText={"Invite"} validationSchema={validationSchema} initialValues={{
                email: "",
                }} fields={[
                    {
                        placeholder: 'example@email.com',
                        label: "E-mail",
                        type: 'email',
                        name: 'email'
                    },
                ]} />
                {/* <form className={styles.form} onSubmit={addEmail}>
                    <label for={'email'}>Email:</label>
                    <div>
                        <input type={'email'} name={'email'} id={'emailField'} required/>
                        <button type={'submit'}>
                            Add
                        </button>
                    </div>
                </form> */}
                {/* <div className={styles.emails}>
                    <Emails />
                </div>
                <Button onClick={invite} style={{width: '100%'}}>
                    Invite
                </Button> */}

            </div>
        </Modal>
    )
}