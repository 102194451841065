import { navigate } from 'gatsby';
import React from 'react';
import MoonLoader from "react-spinners/MoonLoader";
import API from '../../../../../firebase/api';
import AddMembersModal from './AddMembersModal/AddMembersModal';
import * as styles from './group.module.scss';
import Header from './Header/Header';
import Templates from './Templates/Templates';
import SideMenu from '../../../../Pages/Account/AccountSideMenu/AccountSideMenu';


export default (props) => {
    const [groupDetails, setGroupDetails] = React.useState(null)
    const [templates, setTemplates] = React.useState(null)
    const [showMembersModal, setShowMembersModal] = React.useState(false)


    React.useEffect(() => {
        async function onLoad(){
            API.getGroupInfo(props.group).then((info) => {
                if(Object.keys(info).length === 0){
                    navigate('/templates')
                }
                else{
                    setGroupDetails(info)
                }

            })
            API.getGroupTemplates(props.group).then(async (templates) => {
                setTemplates(templates)
            })
        }
        onLoad()
    }, [])

    return(
        <div className={styles.container}>
            <div className={styles.left}>
                <SideMenu />
            </div>
            <div className={styles.right}>
                {showMembersModal ? <AddMembersModal groupId={groupDetails.id} handleClose={() => setShowMembersModal(false)}/> : null}
                    {groupDetails && templates ?
                    <>
                        <Header groupDetails={groupDetails} setShowMembersModal={setShowMembersModal}/>
                        <Templates templates={templates} groupName={groupDetails.name}/>
                    </> 
                    :
                    <MoonLoader
                            css={"margin: auto; margin-top: 45px;"}
                            size={150}
                            color={"#1C1C1C"}
                            loading={true}
                    />}
            </div>
        </div>
    )
}
