import { navigate } from 'gatsby'
import React from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { storage } from '../../../firebase/index'
import FilmSvg from '../../../assets/svg/film-2.svg'
import ImageSvg from '../../../assets/svg/image.svg'
import MoreSvg from '../../../assets/svg/more.svg'
import API from '../../../firebase/api'
import { setCanvasStyle } from '../../../slices/canvas-slice'
import { setThreeDCanvasStyle } from '../../../slices/threed-canvas-screenshot'
import Option from '../Option/Option'
import * as styles from './templatecard.module.scss'
import { hasPaid } from '../Functions';
import {
    setCreditsModal,
    setLoader
} from "../../../slices/user-slice";
import { setCanvas } from '../../../slices/three-slice'
import Dropdown from '../Dropdown/Dropdown'

export default ({template, groupName, setTemplates}) => {
    const [name, setName] = React.useState(template.name)
    const [rename, setRename] = React.useState(false)
    const [deleted, setDeleted] = React.useState(false)
    const [thumbnail, setThumbnail] = React.useState(null)
    const [groups, setGroups] = React.useState(null)
    let updatedDate = new Date(template.updated_ts).toUTCString()

    let dispatch = useDispatch()

    async function loadSavedStyle() {
        let paid = hasPaid();

        if (!paid){
            dispatch(setCreditsModal(true));
            return;
        }

        let url = await storage
            .ref()
            .child("templates")
            .child(template.id)
            .getDownloadURL()
        if(url){
            let res = await fetch(url);
            let blob = await res.blob();
            let data = await blob.text();
            let style = JSON.parse(data);
            style = {
                ...style,
                name,
                uuid: template.id
            }
  
            if(template.type === '3d'){
                if(template.version_id === 2){
                    // load new maker
                    dispatch(setCanvas({...style}))
                    setTimeout(() => {
                        window.location = '/editor/3D'
                    }, 200)
                }
                else{
                    dispatch(setThreeDCanvasStyle({...style, saved:true}))
                    navigate('/editor/3D-depreciated')
                }
            }
            else {
                dispatch(setCanvasStyle({...style, saved: true}));
                navigate("/editor/2D");
            }
        }
        else{
            toast.error('Unable to load template.')
        }
      return;
    }

    async function deleteTemplate(){
        if(await API.deleteTemplate(template.id)){
            setDeleted(true)
            toast.success('✔ Deleted')
        }else {
            toast.error('Something went wrong...')
        }
    }

    function onEnterRename(e) {
        if(e.key === 'Enter'){
            e.preventDefault();
            renameTemplate(e.currentTarget.innerText)
        }
    }
    async function renameTemplate(newName){
        if(name !== newName){
            let response = await API.editTemplate(template.id, {name: newName})
            if(response){
                setName(newName)
                setRename(false)
                toast.success('✔ Renamed')
            }else {
                toast.error('Something went wrong...')
            }
        }
    }


    async function getGroups(){
        setGroups(await API.getUserGroups())
    }

    async function moveTemplate(id, groupId){
        let response = await API.moveTemplate(id, groupId)
        if(response === 200){
            setDeleted(true)
            toast.success('✔ Template moved')
        }else{
            toast.error('Something went wrong...')
        }
    }

    async function duplicateTemplate(){
        dispatch(setLoader(true))
        try {
            let response = await API.copyTemplate(template.id)
            if(response){
                setTemplates(state => [...state, response])
                toast.success('✔ Template duplicated')
            }
        }
        catch {
            toast.error('Something went wrong...')
        }
        dispatch(setLoader(false))
    }

    const listOfGroups = React.useMemo(() => {
        let fetchedGroups = groups ? [{name: 'Home', onClick: () => moveTemplate(template.id)}, ...groups].reduce((list, item) => {
            if((item.name === 'Home' && groupName) || (groupName !== item.name && item.name !== 'Home')){
                list.push({
                    name: item.name,
                    onClick: () => moveTemplate(template.id, item.id)
                })
            }
            return list
        }, []) : [{loading: true}, {loading: true}]
        return fetchedGroups
    }, [groups])


    const options = [
        {
            name: 'Move',
            onHover: getGroups,
            options: listOfGroups
        },
        {
            name: 'Duplicate',
            onClick: duplicateTemplate
        },
        {
            name: 'Rename',
            onClick: () => setRename(true)
        },
        {
            name: 'Delete',
            onClick: deleteTemplate
        },
    ]

    React.useEffect(() => {
        if(template.thumbnail){
            storage
            .ref()
            .child("thumbnails")
            .child(template.thumbnail)
            .getDownloadURL().then(url => {
                setThumbnail(url)
            })
        }
    }, [template.thumbnail])

    const Tags = () => {
        return(
            <div className={styles.tags}>
                <div className={styles.type}>
                    {template.type}
                </div>
                <div className={styles.animated}>
                    {template.animated ? <FilmSvg /> : <ImageSvg />}
                </div>
            </div>
        )
    }

    return deleted ? null : <div className={styles.container} onClick={() => loadSavedStyle(template)}>
        <div className={styles.image}>
            <img src={thumbnail} />
            <Tags />
        </div>
        <div className={styles.lower}>
            <div className={styles.info}>
                <span className={`${styles.name} ${rename ? styles.rename : null}`} onBlur={(e) => renameTemplate(e.currentTarget.innerText)} onKeyDown={onEnterRename} onClick={rename ? (e) => e.stopPropagation() : null} contentEditable={rename}>{name || 'Untitled'}</span>
                <span className={styles.date}>Last Updated: {updatedDate}</span>
            </div>
            <div className={styles.settings}>
                <Dropdown parent={<MoreSvg width={20} height={20}/>} options={options}/>
            </div>
        </div>
    </div>
}