import React from "react";
import AddSvg from "../../../../../assets/svg/add.svg";
import Groups from "../../../../Common/Groups/Groups";
import CreateGroupModal from "./CreateGroupModal/CreateGroupModal";
import * as styles from "./index.module.scss";
import InviteCard from "./InviteCard/InviteCard";

export default ({ groups, setGroups, invites, setInvites }) => {
  const [showCreateModal, setShowCreateModal] = React.useState(false);

  function removeInvite(id){
    let newInvites = invites.filter(x => x.id !== id)
    setInvites(newInvites)
  }

  const Invites = () =>
    invites.map((invite) => {
      return <InviteCard key={invite.id} invite={invite} setGroups={setGroups} removeInvite={removeInvite}/>;
    });

  return (
    <div className={styles.container}>
      {showCreateModal ? (
        <CreateGroupModal
          setGroups={setGroups}
          handleClose={() => setShowCreateModal(false)}
        />
      ) : null}
      <div className={styles.invites}>
        <Invites />
      </div>
      <div className={styles.groups}>
        <Groups list={groups} style={{marginBottom: 20}} />
        <button
          className={styles.createButton}
          onClick={() => setShowCreateModal(true)}
        >
          <span>Create a Group</span>
          <AddSvg />
        </button>
      </div>
    </div>
  );
};
