import React from "react";
import MoonLoader from "react-spinners/MoonLoader";
import API from "../../../firebase/api";
import Breadcrumbs from "../../Common/Breadcrumbs/Breadcrumbs";
import Groups from './Groups/Index/Index';
import * as styles from "./index.module.scss";
import MyTemplates from './MyTemplates/MyTemplates';
import SideMenu from '../../Pages/Account/AccountSideMenu/AccountSideMenu';

export default () => {
  const [loader, setLoader] = React.useState(true);
  const [groups, setGroups] = React.useState([]);
  const [templates, setTemplates] = React.useState([]);
  const [invites, setInvites] = React.useState([])

  React.useEffect(() => {
    async function onLoad() {
      API.getPendingUserInvites().then((invites) => {
        setInvites(invites)
      })
      let groups = await API.getUserGroups()
      let templates = await API.getCurrentUserTemplates()
      alterGroup(groups)
      setTemplates(templates);
      setLoader(false);
    }
    onLoad();
  }, []);

  function alterGroup(result){
    let alteredGroups = result.reduce((list, item) => {
      list.push({...item, display_name: item.name, slug: item.id})
      return list
    }, []) 
    setGroups(alteredGroups)
  }

  return (
    <div className={styles.container}>
        <div className={styles.left}>
            <SideMenu />
        </div>
        <div className={styles.right}>
          <h1>My Templates</h1>
          {loader ? 
          <MoonLoader
            css={"margin: auto; margin-top: 45px;"}
            size={150}
            color={"#1C1C1C"}
            loading={true}
          /> : <>
          <Groups invites={invites} groups={groups} setGroups={alterGroup} setInvites={setInvites} />
          <MyTemplates templates={templates}/>
          </>}
        </div>
    </div>
  );
};
