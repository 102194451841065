// extracted by mini-css-extract-plugin
export const container = "templatecard-module--container--3WFF1";
export const image = "templatecard-module--image--3QC07";
export const tags = "templatecard-module--tags--TjMnk";
export const type = "templatecard-module--type--yf1dP";
export const animated = "templatecard-module--animated--3QKrK";
export const lower = "templatecard-module--lower--1V1Vc";
export const info = "templatecard-module--info--a0NHS";
export const name = "templatecard-module--name--1AXjz";
export const rename = "templatecard-module--rename--24tEi";
export const date = "templatecard-module--date--3jEJq";
export const settings = "templatecard-module--settings--_BClg";