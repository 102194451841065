import Modal from '../Modal/Modal';
import { navigate } from 'gatsby';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCreditsModal } from '../../../slices/user-slice';
import * as styles from './creditsmodal.module.scss';


export default (props) => {
  let show = useSelector(state => state.UserSlice.creditsModal)
  let dispatch = useDispatch()

  const handleClose = () => {
    dispatch(setCreditsModal(false))
  };

  return (
      <Modal
        open={show}
        handleClose={handleClose}
        className={styles.modal}
      >
          <div className={styles.paper}>
            <h2 id="transition-modal-title">It's time to Upgrade 💎 </h2>
            <p id="transition-modal-description">{props.message}</p>
            <div className={styles.buttons}>

            <button onClick={() => {
              handleClose()
              navigate('/plans')
            }}>
              Upgrade
            </button>
                            
            </div>
          </div>
      </Modal>
  );
}