import React from 'react'
import FolderSvg from '../../../../../../assets/svg/folder.svg'
import Modal from '../../../../../Modals/Modal/Modal'
import API from '../../../../../../firebase/api'
import * as Yup from 'yup'
import Form from '../../../../../Common/Form/Form'
import * as styles from './creategroupmodal.module.scss'


export default ({setGroups, handleClose}) => {

    const validationSchema = Yup.object().shape({
        name: Yup.string()
          .required('Group name is required'),
      });

    async function onSubmit(values){
        let response = await API.createNewGroup(values.name)
        if(response){
            let groups = await API.getUserGroups()
            setGroups(groups)
            handleClose()
        }
        return
    }
    return(
        <Modal open={true} handleClose={handleClose}>
            <div className={styles.container}>
                <FolderSvg /> 
                <h1>Create a Group</h1>
                <p>It's easier to organize your templates in groups. Start by giving it a name.</p>
                <Form onSubmit={onSubmit} submitButtonText={"Create"} validationSchema={validationSchema} initialValues={{
                name: "",
                }} fields={[
                    {
                        placeholder: 'Name your group...',
                        label: "Group name",
                        type: 'text',
                        name: 'name'
                    },
                ]} />
            </div>
        </Modal>
    )
}