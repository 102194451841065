import React from 'react'
import Button from '../../../../../Common/Button/Button'
import API from '../../../../../../firebase/api'
import * as styles from './invitecard.module.scss'
import { toast } from 'react-toastify'

export default ({invite, setGroups, removeInvite}) => {

    async function accept(){
        let response = await API.answerInvite(invite.id, 'accepted')
        if(response){
            let groups = await API.getUserGroups()
            setGroups(groups)
            toast.success('Accepted')
            removeInvite(invite.id)
        }
        return
    }
    async function reject(){
        let response = await API.answerInvite(invite.id, 'declined')
        if(response){
            toast.success('Rejected')
            removeInvite(invite.id)
        }
        return
    }
    return(
        <div className={styles.container}>
            <span>You have been invited to a group - {invite.group_name}</span>
            <div className={styles.buttons}>
                <Button className={styles.accept} onClick={accept}>Accept</Button>
                <Button className={styles.reject} onClick={reject}>Reject</Button>
            </div>
        </div>
    )
}