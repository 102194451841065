import React from "react";
import Layout from "../components/layout"
import Group from "../components/Pages/MyTemplates/Groups/Group/Group";
import Index from "../components/Pages/MyTemplates/Index";
import PrivateRoute from "../components/PrivateRoute";
import { Router } from "@reach/router"

export default () => {
  return <Layout>
  <Router basepath="/templates">
    <PrivateRoute component={Index} path="/"/>
    <PrivateRoute component={Group} path="/:group" />
  </Router>
</Layout>
};
