import { Link } from "gatsby";
import React from "react";
import * as styles from "./breadcrumbs.module.scss";

export default ({ breadcrumbs, ignore_last=true }) => {
  return (
    <ol className={styles.pagination}>
      <li>
        <Link className={styles.link} to="/">
          Home
        </Link>
      </li>
      {breadcrumbs.map((item, index) => {
        return (
          <li key={index}>
            {index < breadcrumbs.length - 1 || ignore_last==false ? (
              <Link className={styles.link} to={item.slug}>
                {item.display_name}
              </Link>
            ) : (
              item.display_name
            )}
          </li>
        );
      })}
    </ol>
  );
};
