import React from 'react'
import AddSvg from '../../../../../../assets/svg/add.svg'
import LeaveSvg from '../../../../../../assets/svg/logout.svg'
import TemplateIcon from "../../../../../../assets/svg/account/squares.svg";
import Button from '../../../../../Common/Button/Button'
import API from '../../../../../../firebase/api'
import * as styles from './header.module.scss'
import { navigate } from 'gatsby'
import Breadcrumbs from '../../../../../Common/Breadcrumbs/Breadcrumbs'
import Groups from '../../../../../Common/Groups/Groups'

export default ({groupDetails, setShowMembersModal}) => {
    const {owner, id, members, name} = groupDetails

    const groupMembers = members.reduce((list, member) => {
        list.push({display_name: member.email})
        return list
    }, [])

    async function onLeave(){
        if(!owner){
            await API.leaveGroup(id)
        }
        else {
            await API.editGroup(id, {status: 'deleted'})
        }
        navigate('/templates')
    }

    

    return(
        <div className={styles.container}>
        <h1><TemplateIcon onClick={() => navigate('/templates')}/>&nbsp;&bull;&nbsp;{name}</h1>
        <div className={styles.members}>
            <Groups list={groupMembers} style={{marginBottom: 0}} />
            <Button className={styles.add} onClick={() => setShowMembersModal(true)}>
                <span>
                    Add Members
                </span>
                <AddSvg />
            </Button>
            <Button className={styles.leave} onClick={onLeave}>
                <span>
                    {owner ? 'Delete' : 'Leave'} Group
                </span>
                <LeaveSvg />
            </Button>
        </div>
        </div>
    )
}