import { Link } from 'gatsby'
import React from 'react'
import * as styles from './groups.module.scss'

export default ({list, style}) => {
    return(
        <ul className={styles.container} style={style}>
        {list.map((item, index) => (
        item.slug ?
          <Link to={`${item.slug}`} key={index}>
            <li>{item.display_name}</li>
          </Link> : <li>{item.display_name}</li>
        ))}
      </ul>
    )
}